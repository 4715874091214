import React, { useState } from "react";
import axios from "axios";
import "./Contact.css"; // Import the updated CSS file

const Contact = () => {
  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    message: "",
  });

  const [confirmMessage, setConfirmMessage] = useState(false);
  const [loading, setLoading] = useState(false); // For loading state

  // Handle input change
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, email, phone, message } = data;
    const payload = { first_name, last_name, email, phone, message };

    try {
      const response = await axios
        .post(
          "https://beta-app-backend.orendafinserv.com/api/v1/auth/contact-us?lang=ko",
          payload
        )
        .then((response) => {
          console.log(response);
          setConfirmMessage(true);
        });

      if (response.ok) {
        // Handle success
        console.log("Form data sent successfully");
      } else {
        // Handle error
        console.error("Failed to send form data");
      }
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };
  return (
    <main id="contact" className="contact-section">
      <div className="contact-container">         

          {/* 🔥 Contact Information */}
          <div className=" p-5 first">
          <h2 className="h1 mb-5 " style={{color:"#134084", fontWeight:'bold'}}>Get In Touch</h2>
          <h4 style={{color:"#134084"}}><i><b> Join a community that’s reshaping the financial landscape</b></i></h4>
            <p className="lead" style={{color:"#555"}}>
            The lending sector is undergoing a massive shift, providing businesses with new opportunities to grow, expand, and succeed.            </p>
            <p className="lead" style={{color:"#555"}}>Orenda offers collateral-free funding options, empowering businesses with quick access to capital and ensuring seamless growth without unnecessary roadblocks.</p>

            <h6 style={{color:"#134084"}}><i><b>" Take charge of your future—start your journey with Orenda today!"</b></i></h6>

            {/* <div className="d-flex mb-4">
            <div className="me-4 text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={25}
                  height={25}
                  fill="currentColor"
                  className="bi bi-telephone-outbound"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                </svg>
              </div>            <div>
                <h4 style={{color:"#134084"}}>Phone</h4>
                <p><a href="tel:+919033375852" style={{color:"#555"}}>+91 90333 75852</a></p>
              </div>
            </div>

            <div className="d-flex">
            <div className="me-4 text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={25}
                  height={25}
                  fill="currentColor"
                  className="bi bi-envelope-at"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                  <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                </svg>
              </div>              <div>
                <h4 style={{color:"#134084"}}>Email</h4>
                <p><a href="mailto:sales@orendafinserv.com" style={{color:"#555"}}>sales@orendafinserv.com</a></p>
              </div>
            </div> */}
            </div>
          <div className=" second">
            {!confirmMessage ? (
              <form onSubmit={handleSubmit} id="contact_form">
                {/* 🔥 Name Fields */}
                <div className="contact-row">
                  <div className="col">
                    <label>First Name</label>
                    <input
                      type="text"
                      required
                      maxLength={50}
                      className="form-control"
                      name="first_name"
                      value={data.first_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <label>Last Name</label>
                    <input
                      type="text"
                      required
                      maxLength={50}
                      className="form-control"
                      name="last_name"
                      value={data.last_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* 🔥 Email & Phone */}
                <div className="contact-row">
                  <div className="col">
                    <label>Email Address</label>
                    <input
                      type="email"
                      required
                      maxLength={50}
                      className="form-control"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required
                      maxLength={50}
                      className="form-control"
                      name="phone"
                      value={data.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* 🔥 Message */}
                <div className="mb-4">
                  <label>Message</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows={5}
                    value={data.message}
                    onChange={handleChange}
                  />
                </div>

                {/* 🔥 Submit Button */}
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? "Sending..." : "Submit"}
                </button>
              </form>
            ) : (
              <div className="success-card">
                <p>
                  ✅ Your message has been sent successfully!
                </p>
              </div>
            )}
          </div>
            {/* <div className="d-flex mb-4">
              <div className="me-4 text-primary">📍</div>
              <div>
                <h4 style={{color:"#134084"}}>Address</h4>
                <p className="" style={{color:"#555"}}>1226-27 RK World Tower, Rajkot, India</p>
              </div>
            </div> */}

            
      </div>
    </main>
  );
};

export default Contact;
