import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* 🔥 Contact Info */}
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>📍 <strong>Head Office:</strong> B707, Premium House, Near Ashram Road, Ahmedabad, Gujarat</p>
          {/* <p>📍 <strong>Branch Office:</strong> 1226-27, RK World Tower, Rajkot, Gujarat</p> */}
          <p>📞 <a href="tel:+919033375852">+91 90333 75852</a></p>
          <p>✉️ <a href="mailto:sales@orendafinserv.com">sales@orendafinserv.com</a></p>
        </div>
      </div>

      {/* 🔥 Social Media & Copyright */}
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} Orenda Finserv</p>
        <div className="social-icons">
          <a href="https://www.facebook.com/people/Orenda-Finserv-LLP/61551458152267/" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn.jsdelivr.net/gh/simple-icons/simple-icons/icons/facebook.svg" alt="Facebook" className="social-icon" />
          </a>
          <a href="https://www.instagram.com/orendafinserv/" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn.jsdelivr.net/gh/simple-icons/simple-icons/icons/instagram.svg" alt="Instagram" className="social-icon" />
          </a>
          <a href="https://in.linkedin.com/company/orenda-finserv-llp" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn.jsdelivr.net/gh/simple-icons/simple-icons/icons/linkedin.svg" alt="LinkedIn" className="social-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
