import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import "./assets/css/wave-bsb.css";
import companyLogo from "./assets/img/branding/logo.png";
// import aboutUs from "./assets/img/about/about.jpg";
import "primeicons/primeicons.css";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Header from "./components/header/Header.js";
import Footer from "./components/footer/Footer.js";
import Home from "./pages/home/Home.js";
import About from "./pages/about/About.js";
import Services from "./pages/services/Services.js";
import Team from "./pages/Team";
import { BrowserRouter } from "react-router-dom";
import {  Routes, Route,Router } from "react-router-dom";
import './App.css'
import Partner from "./pages/benefites/Partner.js";
import Borrower from "./pages/benefites/Borrower.js";
import GrowBusiness from "./pages/growBusiness/GrowBusiness.js";
import Testimonials from "./pages/testimonials/Testimonials.js";
import Contact from "./pages/contact/Contact.js";

const ScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
      const sectionId = location.pathname.substring(1) || "home"; // Default to Home if empty
      const section = document.getElementById(sectionId);
      if (section) {
          section.scrollIntoView({ behavior: "smooth" });
      }
  }, [location]);

  return null; // Only triggers scrolling, no UI
};

const App = () => {
  return (
    <div className="body">
      <BrowserRouter>
          <Header />
          <ScrollToSection />
          <Home />
          <About />
           <Services />
          <Partner />
          <Borrower />
          <GrowBusiness/>
          <Testimonials/>
          <Contact />
          <Footer/>
      </BrowserRouter>
      </div>
  );
};


export default App;
