import React from 'react';
import "../.././App.css";
// import companyLogo from "../.././assets/img/branding/image (1).svg";
import company from "../.././assets/img/hero/hero section video.mp4";
import './Home.css'
const Home = () => {
    return (  
<main id="home" className="section animated-bg">
  <section>
    <div className="content-container">
      {/* Left Side - Text */}
      <div className="left-content">
        <h1 className='mb-3'  >
        EXPERIENCE SEAMLESS FUNDING
        </h1>
        <p className='one' >
        Introducing - Alternative Means of Working Capital Financing        </p>
        <p className='two'>
          Quick & Collateral Free
        </p>
      </div>

      {/* Right Side - Image */}
      <div className="right-content">
        <video alt="Funding"  autoPlay muted playsInline  loop style={{border:'2px ', borderRadius:'50px'}}>
        <source  src={company} type="video/mp4"/>
          </video>
      </div>
    </div>
  </section>
</main>
    
    );
};

export default Home;
