import React from "react";
import "./GrowBusiness.css"; 
import icon1 from './../../assets/img/benefits/strategy1.svg';
import icon2 from './../../assets/img/benefits/strategy2.svg';
import icon3 from './../../assets/img/benefits/strategy3.svg';
import icon4 from './../../assets/img/benefits/strategy4.svg';

const GrowBusiness = () => {
  const benefits = [
    { icon: icon1, title: "Leverage Cash Discounts ", content: "Secure better pricing from vendors by making upfront payments" },
    { icon: icon2, title: "Extend Credit Periods ", content: "Strengthen client relationships by offering longer payment cycles" },
    { icon: icon3, title: "Optimize Seasonal Inventory ", content: "Stock up in advance to meet peak demand without cash flow constraints" },
    { icon: icon4, title: "Seize Growth Opportunities ", content: "Ensure timely fund availability for tender applications and business expansion" }
  ];

  return (
    <section className="grow-business">
      <h2 className="grow-heading">Boost Your Business With Our Exclusive Products</h2>
      {/* <p className="grow-subtext">Discover how our tailored solutions can drive your success and maximize your potential.</p> */}

      <div className="benefit-grid">
        {benefits.map((benefit, index) => (
          <div className="benefit-item" key={index}>
            <img src={benefit.icon} alt={benefit.title} className="benefit-icon" />
            <h3 className="benefit-title">{benefit.title}</h3>
            <p className="benefit-content">{benefit.content}</p>
          </div>
        ))}
      </div>

      {/* <div className="cta-section">
        <button className="cta-button">Get Started Today</button>
      </div> */}
    </section>
  );
};

export default GrowBusiness;
