import React from 'react';
import './Services.css'
import "../.././App.css";
import icon1 from './../../assets/img/services/service1.svg';
import icon2 from './../../assets/img/services/service2.svg';
import icon3 from './../../assets/img/services/service3.svg';
import icon4 from './../../assets/img/services/service4.svg';
import icon5 from './../../assets/img/services/service5.svg';
import icon6 from './../../assets/img/services/service6.svg';
import icon7 from './../../assets/img/services/service7.svg';

const Services = () => {
  const firstRowItems = [
    { icon: icon1, title: 'Sales Invoice Discounting' },
    { icon: icon2, title: 'Purchase Order Financing' },
    { icon: icon3, title: 'Refinance on Machinery' },
    { icon: icon4, title: 'Flexi/Hybrid Overdraft Facility' },
    { icon: icon5, title: 'Unsecured Term Loan Facility' },
  ];

  const secondRowItems = [
    { icon: icon4, title: 'Flexi Overdraft Facility' },
    { icon: icon6, title: 'Personal Loan' },
    { icon: icon7, title: 'Instant Advance Salary Loan' },
  ];
  return (
    <main id='products' className='section animated-bg-service'>
 <section className="features-section">
      {/* 🔥 Heading */}
      <h2 className="features-heading">Our Products For MSMEs
      </h2>

      {/* 🔥 First Row */}
      <div className="features-row">
        {firstRowItems.map((item, index) => (
          <div className="feature-item" key={index}>
            <div className="feature-icon">
              <img src={item.icon} alt={item.title} />
            </div>
            <p className="feature-title">{item.title}</p>
          </div>
        ))}
      </div>
      <h2 className="features-heading">Our Products For Salaried Individuals
      </h2>

      {/* 🔥 Second Row */}
      <div className="features-row second-row">
        {secondRowItems.map((item, index) => (
          <div className="feature-item" key={index}>
            <div className="feature-icon">
              <img src={item.icon} alt={item.title} />
            </div>
            <p className="feature-title">{item.title}</p>
          </div>
        ))}
      </div>
    </section>
</main>
  );

};

export default Services;