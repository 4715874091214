import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import companyLogo from "../.././assets/img/branding/ORENDA FINAL LOGO TRANSPARENT.png";
import "./Header.css";

const Header = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false); // ✅ Closed initially
    const menuRef = useRef(null);
    const dropdownRef = useRef(null);

    // 🔥 Toggle menu function
    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
        setDropdownOpen(false); // Close dropdown when menu opens
    };

    // 🔥 Toggle dropdown function (opens/closes "Why Us?" only on big screens)
    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    // 🔥 Close menu & dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current && !menuRef.current.contains(event.target) &&
                dropdownRef.current && !dropdownRef.current.contains(event.target)
            ) {
                setMenuOpen(false);
                setDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    // 🔥 Handle navigation and close menu
    const handleNavigation = (path, id) => {
        navigate(path);
        setMenuOpen(false);
        setDropdownOpen(false);
        setTimeout(() => {
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        }, 100);
    };

    return (
        <header className="curved-navbar">
            <div className="nav-container" ref={menuRef}>
                {/* 🔥 Hamburger Menu Button (Mobile) */}
                <button className="menu-toggle" onClick={toggleMenu}>
                    ☰
                </button>

                {/* 🔥 Logo */}
                <a href="/" aria-label="home">
                    <img src={companyLogo} alt="logo" className="logo" />
                </a>

                {/* 🔥 Navigation Links */}
                <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
                    <ul>
                        <li><a onClick={() => handleNavigation("/#home", "home")}>Home</a></li>
                        <li><a onClick={() => handleNavigation("/#about", "about")}>About</a></li>
                        <li><a onClick={() => handleNavigation("/#products", "products")}>Products</a></li>

                        {/* ✅ "Why Us?" - Dropdown in big screens (Removed in mobile) */}
                        {!menuOpen && <li className="why-us desktop-only " ref={dropdownRef}>
                            <button className="dropdown-toggle" onClick={toggleDropdown}>
                                Why Us? 
                            </button>
                            {dropdownOpen && (
                                <ul className="dropdown-menu">
                                    <li><a onClick={() => handleNavigation("/#partners", "partners")}>Channel Partners</a></li>
                                    <li><a onClick={() => handleNavigation("/#borrowers", "borrowers")}>Borrowers</a></li>
                                </ul>
                            )}
                        </li>}

                        {/* ✅ Separate links in mobile menu (toggle mode) */}
                        {menuOpen && (
                            <>
                                <li className="mobile-only"><a onClick={() => handleNavigation("/#partners", "partners")}>For Channel Partners</a></li>
                                <li className="mobile-only"><a onClick={() => handleNavigation("/#borrowers", "borrowers")}>For Borrowers</a></li>
                            </>
                        )}

                        <li><a onClick={() => handleNavigation("/#contact", "contact")}>Contact</a></li>
                    </ul>
                </nav>

                {/* 🔥 Login Button */}
                <a href="https://partner.orendafinserv.com">
                    <button className="login-btn"> Login</button>
                </a>
            </div>
        </header>
    );
};

export default Header;
