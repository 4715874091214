import React from 'react';
import "../.././App.css";
import './Borrower.css';
import Borrowerimg from './../../assets/img/benefits/Borrower.png'
import Borrower1 from './../../assets/img/benefits/Borrower1.svg'
import Borrower2 from './../../assets/img/benefits/Borrower2.svg'
import Borrower3 from './../../assets/img/benefits/Borrower3.svg'
import Borrower4 from './../../assets/img/benefits/Borrower4.svg'

const Borrower = () => {
  return (
    <main id='borrowers' className='section animated-bg-borrower-page'> {/* 🔥 Changed Class */}
      <div className="section-start-borrower"> {/* 🔥 Changed Class */}
        <div className="benefit-section-borrower"> {/* 🔥 Changed Class */}
          
          {/* Right Side - Image Placeholder */}
          <div className="lp-image-borrower"> {/* 🔥 Changed Class */}
            <img src={Borrowerimg} alt="Lending Partners" />
          </div>
          
          {/* Left Content */}
          <section className="lending-partners-borrower"> {/* 🔥 Changed Class */}
            <div className="lp-text-content-borrower"> {/* 🔥 Changed Class */}
              <h2 className="lp-heading-borrower">For Borrowers</h2> {/* 🔥 Changed Class */}
              <div className="lp-features-borrower"> {/* 🔥 Changed Class */}
                
                <div className="lp-feature-borrower"> {/* 🔥 Changed Class */}
                <img src={Borrower1} className="lp-icon-borrower" alt="Icon" />
                  
                  <div>
                    <h5 className="lp-feature-title-borrower">No Collateral</h5> {/* 🔥 Changed Class */}
                    <p className="lp-feature-text-borrower">Smart, Collateral-Free Working Capital Funding Options – Get the funds you need without pledging any assets, ensuring financial flexibility for business growth.</p> {/* 🔥 Changed Class */}
                  </div>
                </div>

                <div className="lp-feature-borrower">
                  <img src={Borrower2} className="lp-icon-borrower" alt="Icon" /> {/* 🔥 Changed Class */}
                  <div>
                    <h5 className="lp-feature-title-borrower">Fast Disbursement</h5>
                    <p className="lp-feature-text-borrower">Funds in as little as 48 hours. Quick processing and minimal paperwork enable businesses to access capital without delays.</p>
                  </div>
                </div>

                <div className="lp-feature-borrower">
                  <img src={Borrower3} className="lp-icon-borrower" alt="Icon" />
                  <div>
                    <h5 className="lp-feature-title-borrower">Flexible Repayment Options</h5>
                    <p className="lp-feature-text-borrower">Tailored to your Business Needs. Choose a repayment plan that aligns with your cash flow, ensuring ease and sustainability.</p>
                  </div>
                </div>

                <div className="lp-feature-borrower">
                  <img src={Borrower4} className="lp-icon-borrower" alt="Icon" />
                  <div>
                    <h5 className="lp-feature-title-borrower">Higher Eligibility</h5>
                    <p className="lp-feature-text-borrower">More Businesses Qualify Compared to Traditional Loans. Streamlined approval process makes funding accessible to a wider range of businesses.</p>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default Borrower;
