import React from 'react';
import "../.././App.css";
import './Partner.css';
import Partnerimg from './../../assets/img/benefits/Partner.png'
import Partner1 from './../../assets/img/benefits/Partner1.svg'
import Partner2 from './../../assets/img/benefits/Partner2.svg'
import Partner3 from './../../assets/img/benefits/Partner3.svg'
import Partner4 from './../../assets/img/benefits/Partner4.svg'
import Partner5 from './../../assets/img/benefits/Partner5.svg'

const Partner = () => {
  return (
    <main id='partners' className='section animated-bg-partner'>
      <div className="section-start">
        <div className="benefit-section">
          {/* Left Side Content */}
          <section className="lending-partners">
            <div className="lp-text-content">
              <h2 className="lp-heading">For Channel Partners</h2>
              <div className="lp-features">
                <div className="lp-feature">
                  <img src={Partner1} className="lp-icon" alt="Icon" />
                  <div>
                    <h5 className="lp-feature-title">Partner Portal</h5>
                    <p className="lp-feature-text">India’s first & only corporate DSA with a ZERO TOUCH PARTNER PORTAL.</p>
                  </div>
                </div>
                <div className="lp-feature">
                  <img src={Partner2} className="lp-icon" alt="Icon" />
                  <div>
                    <h5 className="lp-feature-title">100% Transparency</h5>
                    <p className="lp-feature-text">All payout deals documented in our partner portal</p>
                  </div>
                </div>
                <div className="lp-feature">
                  <img src={Partner3} className="lp-icon" alt="Icon" />
                  <div>
                    <h5 className="lp-feature-title">Digital Payout Claiming Process</h5>
                    <p className="lp-feature-text"> You claim your own payouts. NO INTERMEDIARY!</p>
                  </div>
                </div>
                <div className="lp-feature">
                  <img src={Partner4} className="lp-icon" alt="Icon" />
                  <div>
                    <h5 className="lp-feature-title">Dedicated Customer Support</h5>
                    <p className="lp-feature-text">Instant help from our managers in every step of our partnership journey</p>
                  </div>
                </div>
                <div className="lp-feature">
                  <img src={Partner5} className="lp-icon" alt="Icon" />
                  <div>
                    <h5 className="lp-feature-title">Structuring Support</h5>
                    <p className="lp-feature-text"> Every suboptimal deal to your client is a breach of trust. Your clients trust you to find the best financial solutions—Orenda makes sure you deliver.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Right Side - Image Placeholder */}
          <div className="lp-image">
            <img src={Partnerimg} alt="Lending Partners" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Partner;
